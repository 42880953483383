<div class="row" *ngIf="shopSettings$ | async as shopSettings">
  <ng-container *ngIf="shopSettings.isShopEnabled">
    <div class="col-xs-12 availability">
      <p>Shipping Option</p>
      <nb-radio-group
        disabled
        name="{{ lineItem.id }}"
        size="tiny"
        [(ngModel)]="retrievalMethodType"
      >
        <nb-radio value="delivery">
          <nb-icon icon="car-outline" status="success"></nb-icon> Delivery</nb-radio
        >
        <nb-radio disabled value="pickup"
          ><nb-icon icon="home-outline"></nb-icon>Store Pickup</nb-radio
        >
      </nb-radio-group>
    </div>
    <div class="col-xs-12 pricing text-center">
      {{ lineItem.price_data?.product_data?.name }}
      <p class="h5">{{ lineItem.price_data?.unit_amount | currency }}</p>
    </div>
    <div class="col-xs-12 no-padding">
      <nb-actions size="tiny" fullWidth>
        <nb-action>
          <input
            [(ngModel)]="qty"
            placeholder="qty"
            fieldSize="tiny"
            step="1"
            [min]="minQty"
            [max]="maxQty"
            nbInput
            type="number"
          />
          <button (click)="addToCart()" size="tiny" hero status="success" nbButton>
            <nb-icon icon="shopping-cart-outline"></nb-icon>
            Add to cart
          </button>
        </nb-action>
      </nb-actions>
    </div>
  </ng-container>
  <ng-container *ngIf="!shopSettings.isShopEnabled">
    <div class="col-xs-12">
      <p>Online shopping is currently unavailable.</p>
    </div>
  </ng-container>
</div>
<ng-template #checkout let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Added To Cart</nb-card-header>
    <nb-card-footer>
      <nb-button-group>
        <button nbButton outline status="primary" (click)="ref.close()">Continue Shopping</button>
        <button nbButton status="primary" (click)="ref.close(true)">Checkout</button>
      </nb-button-group>
    </nb-card-footer>
  </nb-card>
</ng-template>
