import { NbAuthModule } from '@nebular/auth';
import { ShellModule } from './shared/shell/shell.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService
} from '@angular/fire/compat/performance';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';

import { environment } from './../environments/environment';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, Injector, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbSidebarModule,
  NbToastrService,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbToastrModule,
  NbWindowModule
} from '@nebular/theme';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FirebaseAuthInterceptor } from './interceptors/firebase-auth.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotFoundGuard } from './shared/404/not-found.guard';

declare let newrelic;
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: Error): void {
    if (error?.name !== 'FirebaseError' && error?.message?.indexOf('FirebaseError') < 0) {
      const toastService = this.injector.get(NbToastrService);
      const message = error.message;
      toastService.danger(message, 'We hit a glitch', {
        duration: 10000,
        destroyByClick: true
      });
    }
    console.error('Global Error Handler', error);
    newrelic.noticeError(error, { globalHandler: true });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbAuthModule.forRoot(),
    NgxSpinnerModule,
    VirtualScrollerModule,
    ShellModule,
    SharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: SETTINGS,
      useValue: { minimumFetchIntervalMillis: environment.production ? 300_000 : 5_000 }
    },
    PerformanceMonitoringService,
    UserTrackingService,
    ScreenTrackingService,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: true,
        anonymize_ip: true
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthInterceptor, multi: true },
    NotFoundGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
